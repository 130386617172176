/* You can add global styles to this file, and also import other style files */
$primary: var(--primary-color);
$secondary: var(--secondary-color);
$third: var(--third-color);
$fifth: var(--fifth-color);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body.dark-mode {
  background-color: #17181B !important;
  color: #dfe6e9;

  .dark-theme {
    background-color: #17181B !important;
    color: #dfe6e9;
  }

  .navbar {
    background: transparent !important;
    color: #dfe6e9 !important;
  }
  .nav-link {
    color: #dfe6e9 !important;
  }
  .dark {
    color: #EDC9FF;

  }
  .dark_theme {
    background-color: transparent;
  }
  .logo_cont {
    background: transparent;
  }
  * {
    background: transparent;
  }
  .dark_inputbox {
    background: transparent;
  }
  .login-container {
    background-color: #413945;
    box-shadow: 0px 0px 4px #413945;
    border: 2px solid #FFFFFF80;
  }
  .login-text {
    background: transparent;
  }
  .form_container {
    background: transparent;
  }
  input[type=text] {
    background: transparent;
    color: white;
  }
  .forrm {
    background: transparent !important;
  }
  .dark-mode-heading {
    color: #FFFFFFDE;
  }
  .env_cont {
    color: $secondary;
    filter: brightness(3.5);
  }
  .heading_dark {
    color: #FFFFFFBD;
  }
  .dark_card {
    background-color: $primary ;
  }
  .card-text {
    color: #FFFFFFBD;
  }
  .dark_env {
    background: $fifth;
  }
  .darkk {
    background-color: #17181B;
    box-shadow: 0px 2px 2px #00000029;
  }
  .dark_menu {
    background: #525E603D 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 1px #00000029;
    border: 1px solid #8825C026;
  }
  .dark_menu:hover {
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid $secondary !important;
    color: #E7BCFF;
  }
  .left_pannel {
    background-color: #413945;
  }
  .dark-card {
    background: #28282E 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF36;
  }
  .dark_card1 {
    background-color: $primary;
  }
  .dark-modal {
    background-color: #17181B;
    border: 1px solid #FFFFFF36;
  }
  .dark-card-content {
    color: #FFFFFF;
  }
  .btn-plus {
    background-color: $primary;
  }
  // .table th{
  //     color:#FFFFFFDE ;
  // }
  .Active {
    color: #187209 !important;
  }
  .dark-head {
    color: #FFFFFFDE;
  }
  .dark_list {
    background-color: #DEC1EE1F;
  }
  .dark_subHeading {
    color: #FFFFFFDE;
  }
  .dark_spec {
    color: #FFFFFFDE;
  }
  .dark_spe {
    color: #FFFFFFDE;
    background-color: black;
  }
  .prod_spe {
    background-color: black;
  }
  .dark_sidebar {
    background: #525E603D 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 1px #00000029;
    border: 1px solid #8825C026;
  }
  .form-group>label {
    background-color: #17181B !important;
  }
  .form-group1>label {
    background-color: #17181B !important;
  }
  .form-group2>label {
    background-color: #17181B !important;
  }
  .form-gro>label {
    background-color: #17181B !important;
  }
  .card-body:hover {

    border: 1px solid $secondary;
    border-radius: 5px;
    opacity: 1;
  }
  .dark_table {
    color: #FFFFFFDE !important;
  }
  .nav {
    background: transparent linear-gradient(180deg, #75757552 0%, #9F9F9F12 100%) 0% 0% no-repeat padding-box !important;
    // box-shadow: 0px 2px 2px #00000029 !important;
    padding-top: 10px;
  }
  .background-color {
    background: #2C2A2A;
    padding: 0px !important;
  }
  .server_spec {
    font-size: 14px;
    font-family: 'Nunito Sans';
    letter-spacing: 0px;
    color: #FFFFFFDE;
  }
  .list_detail {
    background: transparent !important;
    box-shadow: 0px 2px 2px #00000029;
    border: 1px solid #DD00005C;
    border-radius: 5px;
    // position: relative;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
  }
  .text1 {
    font-family: 'Nunito Sans';
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  .heading_para {
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFFBD;
    opacity: 1;
    margin-top: 10%;
  }
  .ip_add {
    font-size: 12px;
    letter-spacing: 0px;
    color: #FFFFFFDE;
  }
  .spec {
    font-size: 10px;
    letter-spacing: 0px;
    color: white;
    cursor: pointer;
  }
  .side_bar_element {
    /* border: 1px saddlebrown; */
    width: 100%;
    background: #F9F1FD 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #3D85C536;
    border-radius: 5px;
    display: flex;
    height: 58px;
    background: transparent;
  }
  .replicating_side_bar {
    background: $third;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $secondary;
    cursor: pointer;
  }
  .side_logo {
    background: $third 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 25px;
    height: 27px;
  }
}
body.light-mode {
  background-color: white;
  color: #2d3436;

  .side_logo {
    background: $primary 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 25px;
    height: 27px;
  }
  .side_bar_element {
    /* border: 1px saddlebrown; */
    width: 100%;
    background: #F9F1FD 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #3D85C536;
    border-radius: 5px;
    display: flex;
    height: 58px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }
  .replicating_side_bar {
    background: $primary;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid $secondary;
    cursor: pointer;
  }
  .spec {
    font-size: 10px;
    letter-spacing: 0px;
    color: #707070;
    cursor: pointer;
  }
  .ip_add {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000DE;
  }
  .text1 {
    font-family: 'Nunito Sans';
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000DE;
  }
  .list_detail {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    border: 1px solid #DD00005C;
    border-radius: 5px;
    // position: relative;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
  }
  .form-group>label {
    background-color: white;
  }
  .form-group1>label {
    background-color: white;
  }
  .form-group2>label {
    background-color: white;
  }
  .form-gro>label {
    background-color: white;
  }
  .nav {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // box-shadow: 0px 2px 2px #00000029;
    padding-top: 10px;
  }
  .background-color {
    background-color: $primary;
    padding: 0px !important;
  }
  .server_spec {
    font-size: 14px;
    font-family: 'Nunito Sans';
    letter-spacing: 0px;
    color: #000000DE;
  }
}
.select {
  // -webkit-appearance: none;
  padding: 12px 100px 12px 20px !important;
  // -moz-appearance: none;
  background: #272b2f;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='35' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 8px;
  border: 1px solid #272b2f;
  border-radius: 2px;
  font-size: 18px;
  margin-right: 2rem;
  padding: 1rem;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  padding-right: 2rem;
}
.comiseo-daterangepicker-triggerbutton.ui-button {
  text-align: left;
  min-width: 18em;
  padding: 6px 6px 6px 0px !important;
  background: #272b2f !important;
  border: 1px solid #272b2f !important;
  border-radius: 2px;
  font-size: 14px !important;
  color: #fff;
  font-weight: 600;
  border-radius: 10px !important;
}
.comiseo-daterangepicker-triggerbutton .ui-button-icon {
  /* fix v1.12 */
  position: absolute;
  right: 0.5em;
  top: 50%;
  margin-top: -8px;
}
.comiseo-daterangepicker {
  position: absolute;
  padding: 5px;
  z-index: 99999 !important;
}
.comiseo-daterangepicker-mask {
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* required for IE */
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.comiseo-daterangepicker-presets,
.comiseo-daterangepicker-calendar {
  display: table-cell;
  vertical-align: top;
  height: 230px;
}
.comiseo-daterangepicker-right .comiseo-daterangepicker-presets {
  padding: 2px 7px 7px 2px;
}
.comiseo-daterangepicker-left .comiseo-daterangepicker-presets {
  padding: 2px 2px 7px 7px;
}
.comiseo-daterangepicker-presets .ui-menu {
  padding: 2px;
  /* fix v1.11 */
  white-space: nowrap;
}
.comiseo-daterangepicker-presets .ui-menu-item {
  /* fix v1.11 */
  padding: 0;
}
.comiseo-daterangepicker-presets .ui-menu-item>* {
  /* fix v1.11 */
  text-decoration: none;
  display: block;
  padding: 2px 0.4em;
  line-height: 1.5;
  min-height: 0;
  /* support: IE7 */
}
.comiseo-daterangepicker .ui-widget-content,
.comiseo-daterangepicker .ui-datepicker .ui-state-highlight {
  border-width: 0;
}
.comiseo-daterangepicker>.comiseo-daterangepicker-main.ui-widget-content {
  border-bottom-width: 1px;
}
.comiseo-daterangepicker .ui-datepicker .ui-datepicker-today .ui-state-highlight {
  border-width: 1px;
}
.comiseo-daterangepicker-right .comiseo-daterangepicker-calendar {
  border-left-width: 1px;
  padding-left: 5px;
}
.comiseo-daterangepicker-presets {
  display: none;
}
.comiseo-daterangepicker-left .comiseo-daterangepicker-calendar {
  border-right-width: 0px;
  padding-right: 0px;
}
.comiseo-daterangepicker-right .comiseo-daterangepicker-buttonpanel {
  float: left;
}
.comiseo-daterangepicker-left .comiseo-daterangepicker-buttonpanel {
  float: right;
}
.comiseo-daterangepicker-buttonpanel>button {
  margin-top: 6px;
}
.comiseo-daterangepicker-right .comiseo-daterangepicker-buttonpanel>button {
  margin-right: 6px;
}
.comiseo-daterangepicker-left .comiseo-daterangepicker-buttonpanel>button {
  margin-left: 6px;
}
/* themeable styles */
.comiseo-daterangepicker-calendar .ui-state-highlight a.ui-state-default {
  background: #b0c4de;
  color: #fff;
}
.ui-datepicker table {
  width: 100%;
  font-size: 12px !important;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
}
.ui-widget .ui-widget {
  font-size: 12px !important;
}
.loader-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  line-height: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 10px;
  /* background-color: #3d434b; */
  text-align: center;
  z-index: 10;
}
.firsts,
.seconds,
.thirds.big {
  width: 17px;
  height: 17px;
}
.firsts {
  background: #5d5e5e;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.seconds {
  background: yellow;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.thirds {
  background: rgb(231, 117, 117);
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;

}
@media (max-width:767px) {
  .select {
    font-size: 14px;
    width: 100%;
  }
}